/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

body, html 
{
  height: 100%;
  background-color: lightgray;
}

.main
{
    height: 100%;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.page {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.app-container {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.progress-bar-container {
  display: flex;
  width: 60%;
  justify-content: center;
}

.diff-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  padding: 20px;
}

.code-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 42%;
}

.code-block {
  width: 100%;
}

.button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 15%;
}
